<template>
  <div>
    <!-- 大赛动态 -->
    <div class="header-box" />

    <!-- banner -->
    <div
      v-if="screenWidth>1200"
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center -100px', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b'}"
    />
    <div
      v-else
      class="banner"
      :style="{ backgroundImage: 'url('+banner+')', backgroundPosition: 'center 60px', backgroundRepeat: 'no-repeat', backgroundColor: '#1b1b1b', backgroundSize: '100%, 100%'}"
    />

    <!-- 动态vue -->
    <router-view />

  </div>
</template>

<script>
import { fetchResource } from '@/api/homepage'

export default {
  data() {
    return {
      // 屏幕大小
      screenWidth: '',
      screenHeight: '',

      banner: ''
    }
  },
  created() {
    // console.log('I am info page')
  },
  mounted() {
    // 实时监听窗口大小的变化
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
        this.screenHeight = document.body.clientHeight
        // console.log('width ' + this.screenWidth)
        // console.log('height ' + this.screenHeight)
      })()
    }
    this.fetchResource()
  },
  methods: {
    // 获取banner图
    fetchResource() {
      fetchResource().then(res => {
        const resource = res.data
        resource.bannerDir = this.PR.previewOrigin(resource.banner)
        this.banner = `${resource.bannerDir}/banner.jpg`
      })
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

/* .header-box{
    height: 60px;
    background-color: var(--background-color);
} */

.banner {
  width: 100%;
  height: 550px;
  background-color: var(--background-color);
}

@media screen and (max-width: 1200px) {
  .banner {
    height: 250px;
    background-color: var(--background-color);
  }
}

</style>
